<template>
  <div>
    <v-container>
      <v-layout row wrap class="text-xs-center">
        <v-flex xs12 class="text-xs-center">
          <span class="headline">Success!</span>
        </v-flex>
        <v-flex class="text-xs-center">
          <v-icon x-large color="success white--text">check_circle</v-icon>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Paypal from '@/services/PaypalService'

export default {
  name: 'Success',
  data () {
    return {

    }
  },
  methods: {
    successPayment () {
      Paypal.successPayment({
        paymentId: this.$route.query.paymentId,
        payerId: this.$route.query.PayerID
      })
        .then(res => {
          console.log(res)
        })
    }
  },
  created () {
    this.successPayment()
  }
}
</script>

<style scoped>

</style>