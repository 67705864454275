import Api from './Api'

export default {
  createPayment (params) {
    return Api().post('/api/paypal/pay', params)
  },
  successPayment (params) {
    console.log(params)
    return Api().get(`/api/paypal/success/${params.paymentId}/${params.payerId}`)
  }
}